import React from 'react'
import { graphql } from 'gatsby'
import { NavWithLogoOnly } from '../components/ui/navigation/navigation'
import Container from '../components/ui/container'

const Go = (props) => {
  React.useEffect(() => initTypeform());
  return (
    <div>
      <NavWithLogoOnly />
      <Container>
        <div
            className="typeform-widget"
            data-url={props.data.contentfulWebsite.gourl}
            data-transparency="50"
            data-hide-headers={true}
            style={{
              width: '100%',
              height: '500px'
            }}/>
      </Container>
    </div>
  );
}

export default Go;

function initTypeform() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/"; if(!gi.call(d,id)) { js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } }

export const pageQuery = graphql`
  query GoQuery {
    contentfulWebsite(version: { eq: "website-v3" }) {
      gourl
    }
  }
`
